import React from "react"

// https://stackoverflow.com/questions/23583245/add-transition-while-changing-img-src-with-javascript
const slides = [
  {
    src: "/slideshow/mail.png",
    alt: "Direct Mail Production… SEE BETTER RESULTS",
  },
  {
    src: "/slideshow/data.png",
    alt: "Experienced Data Processing Team… SEE BETTER RESULTS",
  },
  {
    src: "/slideshow/laser.png",
    alt: "High Quality Laser Personalization… SEE BETTER RESULTS",
  },
  {
    src: "/slideshow/partner.png",
    alt: "Partner with BIGEYE Direct and… SEE BETTER RESULTS",
  },
]

export function Slideshow() {
  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [isLoaded, setIsLoaded] = React.useState(false)

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide(slide => {
        if (slide === slides.length - 1) return 0
        return slide + 1
      })
    }, 9000)

    return () => clearInterval(interval)
  }, [])

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoaded(true)
    }, 7000)
    return () => clearTimeout(timer)
  }, [])

  return (
    <React.Fragment>
      <style>
        {`
        .hidden {
          opacity: 0;
        }

        .visible {
          opacity: 1;
        }

        .slide {
          position:absolute;
          transition: opacity 0.5s linear;
          width: 926px;
          height: 215px;
        }
        .slideshow-container {
          position: relative;
          width: 926px;
          height: 215px;
        }
        `}
      </style>
      <div className="slideshow-container">
        {isLoaded ? (
          slides.map((slide, index) => (
            <img
              className={`slide ${
                index === currentSlide ? "visible" : "hidden"
              }`}
              src={slide.src}
              alt={slide.alt}
              key={index}
            />
          ))
        ) : (
          <img
            className="slide visible"
            src={slides[0].src}
            alt={slides[0].alt}
          />
        )}
      </div>
    </React.Fragment>
  )
}
