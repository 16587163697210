import React from "react"
import { Link } from "gatsby"
import { Seo } from "../components/Seo/Seo"
import { Slideshow } from "../components/Slideshow/Slideshow"

export default function IndexPage() {
  return (
    <React.Fragment>
      <Seo
        title="Big Eye Direct Mail Marketing"
        description="Big Eye direct produces effective direct mail marketing campaigns for businesses in the Herndon and surrounding areas. Big Eye Direct is a reliable source for your data production."
      />
      <div id="wrapper">
        <div id="topper">
          <div id="logo">
            <Link to="/">
              <img
                src="/wordmark-dark.svg"
                width="234"
                height="108"
                alt="BIGEYE Direct Mail Production"
              />
            </Link>
          </div>
          <div id="slogan">DIRECT MAIL PRODUCTION</div>
          <div id="contact">
            <h2>703-955-3017</h2>
            <p>
              <a href="mailto:damon@bigeyedirect.com">info@bigeyedirect.com</a>
            </p>
          </div>
        </div>
        <div id="content">
          <div id="nav">
            <ul>
              <li className="active">
                <Link to="/">HOME</Link>
              </li>
              <li>
                <Link to="/services/">SERVICES</Link>
              </li>
              <li>
                <Link to="/facility/">OUR FACILITY</Link>
              </li>
              <li>
                <Link to="/security/">SECURITY</Link>
              </li>
              <li>
                <Link to="/about/">ABOUT US</Link>
              </li>
              <li className="endNav">
                <Link to="/contact/">CONTACT US</Link>
              </li>
            </ul>
          </div>
          <div className="clear"></div>
          <div id="flasharea">
            <Slideshow />
          </div>
          <div id="textarea">
            <h2>Welcome to BIGEYE Direct</h2>
            <p>
              Producing effective direct mail marketing campaigns IS our
              business. Professionals who want an edge must have a reliable
              source for their data processing, personalization and mailing
              programs. BIGEYE Direct is that source.&nbsp;{" "}
            </p>
            <p>
              Serving our clients as a trusted ally, BIGEYE Direct provides the
              loyalty of a business partner and the economics of an outside
              supplier. We ensure that our clients get what they need to
              effectively communicate through the mail with maximum efficiency
              and reliability.{" "}
            </p>
            <p>
              We are committed to supporting our clients by delivering high
              quality, cost effective solutions in an efficient and reliable
              manner.&nbsp; So welcome, you’ve found BIGEYE Direct.&nbsp; Expect
              to see better results!
            </p>
          </div>
        </div>
        <div id="footer">
          <a
            rel="noopener noreferrer"
            href="http://maps.google.com/maps?f=q&amp;source=s_q&amp;hl=en&amp;geocode=&amp;q=13860+Redskin+Drive+Herndon,+VA+2017&amp;sll=37.0625,-95.677068&amp;sspn=52.637906,88.330078&amp;ie=UTF8&amp;hq=&amp;hnear=13860+Redskin+Dr,+Herndon,+Fairfax,+Virginia+20171&amp;z=17"
            target="_blank"
          >
            13860 Redskin Drive • Herndon, VA 20171
          </a>
        </div>
        <div id="copyright">
          ©2011 BIGEYE Direct. All rights reserved. Sitemap
        </div>
      </div>
    </React.Fragment>
  )
}
